@import '../../styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}

@import '../../styles/variables.scss';

.formEditForm {
  height: max-content;
  position: relative;
  background-color: #ffffff;

  &__controls {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  &__formInputs {
    display: grid;
    gap: 0.5rem;
  }

  &__formFields {
    display: grid;
    grid-gap: 1rem;
    margin-top: 1rem;
  }

  &__addFieldButton {
    place-self: start;
    margin-top: 1rem;
  }
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
}

.container {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.button {
  justify-self: flex-end;

  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

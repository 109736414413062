@import '../../styles/variables.scss';

.wrapper {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.container {
  display: grid;
  grid-gap: 0.25rem;
}

.questionMarkCircleIcon {
  width: 1.25rem;
  height: 1.25rem;
  fill: #9ca3af;
  cursor: pointer;
}

.switchTooltipContent {
  max-width: 20rem;
  margin: 0;
  padding: 0;
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  width: max-content;
  color: white;
  border-radius: $border-radius-round;
  box-shadow: 0px 1px 2px 0px #0000000d;
  padding: 0.125rem 0.625rem;
}

.text {
  place-self: center;
  font-family: Poppins;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: center;
}

@import '../../styles/variables.scss';

.container {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.titleInput {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: $border-radius-base;
  padding: 0.5rem 0.75rem;
  margin: 0;

  &:hover {
    border-color: #d1d5db;
  }

  &:focus {
    border-color: $black;
  }
}

.imagePreviewContainer {
  position: relative;
  width: fit-content;
  margin-bottom: 1rem;
}

.imagePreview {
  width: 20rem;
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-base;
}

.closeIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  padding: 0.25rem;
  background-color: white;
  border-radius: $border-radius-round;
  box-shadow: 0px 1px 4px 0px #00000040;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

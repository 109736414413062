@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.richTextInput {
  &__editor {
    @include rich-text;
    box-shadow: none;
    border: 1px solid #e5e7eb;
    border-radius: $border-radius-base;
    color: $black;

    &:hover {
      border-color: #d1d5db;
    }

    &:focus {
      border-color: $black;
    }

    :global .w-md-editor-toolbar {
      width: 100%;
      padding: 0.5rem;
      background-color: $white;
      border-bottom: #e5e7eb solid 1px;

      ul {
        padding-inline-start: 0;
      }
    }

    :global .w-md-editor-bar {
      right: 0.5rem;
    }

    :global .w-md-editor-toolbar li > button {
      height: auto;
    }

    :global .w-md-editor-preview {
      @include rich-text;
      padding: 10px;
    }
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

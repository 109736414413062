@import '../../styles/variables.scss';

.pageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: $min-xl-wide-desktop-width;
  height: 100%;
  width: 100%;

  padding: $page-top-space $page-horizontal-space $page-bottom-space;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    padding-top: $gap-regular;
    padding-left: $gap-regular;
    padding-right: $gap-regular;
    padding-bottom: $gap-large;
  }
}

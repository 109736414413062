@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  max-width: 50rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.formFields {
  display: grid;
  gap: 2rem;
}

.label {
  font-weight: bold;
  font-size: $font-size-large;
  margin-bottom: $gap-small;
}

.description {
  font-weight: $font-weight-regular;
  font-size: $font-size-regular;
  margin-bottom: $gap-small;
}

.field {
  font-weight: bold;
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.textAreaValue {
  @include font-paragraph;

  white-space: pre-line;
}

.video {
  max-width: 500px;
  border-radius: $border-radius-large;
  overflow: hidden;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    max-width: 100%;
  }
}

@import '../../styles/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.title {
  font-size: $font-size-title;
  font-weight: bold;
}

.wrapper {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #f7f6f3;
}

.approval {
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  margin: 0.25rem;
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: #e7d4d4;
  gap: 1.5rem;
  font-weight: bold;
}

.approved {
  background-color: #fff;
}
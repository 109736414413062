@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header {
  font-size: 1.5rem;
  font-weight: bold;
  color: $black;
  margin-bottom: 1rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.field {
  word-break: break-word;

  > h3,
  p {
    font-size: 1rem;
  }

  > p {
    margin: 0;
  }
}

.label {
  font-weight: bold;
}

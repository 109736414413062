@import '../../styles/variables.scss';

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.headerContainer {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.header {
  font-weight: bold;
}

.ideaName {
  font-size: 2rem;
  color: #f9c623;
}

@import '../../styles/variables.scss';

.wrapper {
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;

  box-shadow: $box-shadow-large;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 6px 0px #00000030;
  }
}

.imageAndTagsContainer {
  position: relative;
}

.spotlightTag {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.moonChallengeTag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.625rem;
}

.image {
  width: 100%;
  height: 16.25rem;
  object-fit: cover;
  box-shadow: 0px 3.5236642360687256px 5.285496234893799px -1.7618321180343628px #0000000d;
  box-shadow: 0px 8.809160232543945px 13.213741302490234px -2.6427481174468994px #0000001a;
}

.content {
  padding: 1.875rem;
}

.badgesAndComments {
  display: grid;
  grid-auto-flow: column;
}

.badges {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 0.5rem;
}

.comments {
  justify-self: right;
}

.chatBubblesIcon {
  fill: #22282f;
}

.commentCount {
  color: #22282f;
  margin-left: 0.25rem;
}

.titleAndDescription {
  padding: 1.125rem 0;
}

// TODO: Unify typography on a later PR
.title {
  $max-lines: 2;
  $line-height: 22px;

  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: $line-height;
  letter-spacing: 0em;
  text-align: left;

  height: $line-height * $max-lines;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $max-lines;
  -webkit-box-orient: vertical;
}

.description {
  $max-lines: 3;
  $line-height: 20px;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: $line-height;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
  margin-top: 0.5rem;

  height: $line-height * $max-lines;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $max-lines;
  -webkit-box-orient: vertical;
}

.profile {
  display: flex;
  align-items: center;
}

.profileContent {
  margin-left: 0.625rem;
}

// TODO: Unify typography on a later PR
.profileName {
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

// TODO: Unify typography on a later PR
.publishedAt {
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d7d7d;
}

@import '../../styles/variables.scss';

.container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem 2rem 20rem;
  bottom: -10rem;
  left: 0;
  width: 100%;
  height: 10rem;
  background-color: $brand-color;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
}

.shown {
  transform: translateY(-10rem);
}

.buttons {
  display: flex;
  align-items: center;
}

.button {
  width: 6rem;
  height: 2rem;
  margin: 0 1rem;
}

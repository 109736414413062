
@import '../../styles/variables.scss';

.wrapper {
  position: absolute;
  right: 0.25rem;
  top: 2.75rem;
  width: 10.25rem;
  background-color: $white;
  box-shadow: $box-shadow-small;
  padding: 1.5rem;
  z-index: 2000;
}

.container {
  display: grid;
  align-items: center;
  gap: 1rem;
}

.item {
  cursor: pointer;
  color: $hard-text-color;
}

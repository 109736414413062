@import '../../styles/variables.scss';

.grid {
  max-width: $min-xl-wide-desktop-width;
  width: 100%;
  display: grid;
}

.columnHeader {
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
  background-color: $black;
  color: $white;
  font-weight: 400;
  position: sticky;
  top: 0;
  padding: 0.75rem 1rem;
  z-index: 1;
}

.firstColumnHeader {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.lastColumnHeader {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.gridCell {
  display: grid;
  align-content: center;
  justify-items: center;
  text-align: center;
  padding: 0.5rem 1rem;
}

.even {
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

@import '../../styles/variables.scss';

.optionsModal {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 2rem;
  width: max-content;
  background-color: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  z-index: $z-index-popup-menu;
}

.option {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: $border-radius-base;
  color: $black;

  &:hover {
    color: $white;
    background-color: $black;
  }
}

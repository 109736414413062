@import '../../styles/variables.scss';

.userGroupChallenge {
  display: flex;
  align-items: center;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
}

@import '../../styles/variables.scss';

.ideaComments {
  display: grid;

  h2 {
    justify-self: center;
    font-size: $font-size-large;
    font-weight: bold;
    color: $black;
  }

  &__richTextInput {
    box-shadow: $box-shadow-base;
    border: none;
    border-radius: 1rem;
    overflow: hidden;
  }

  &__submitButton {
    width: 8rem;
    justify-self: center;
    margin-top: 1rem;

    @media only screen and (max-width: $min-tablet-landscape-width) {
      width: 100%;
      justify-self: unset;
    }
  }

  &__comments {
    display: grid;
    row-gap: 1rem;
    margin-top: 2rem;
  }

  button {
    justify-self: center;
    margin-top: 1rem;
  }
}

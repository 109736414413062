@import '../../styles/variables.scss';

.wrapper {
  width: max-content;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: $border-radius-base;
  padding: 0.5rem 0.75rem;
  margin: 0;

  &:hover {
    border-color: #d1d5db;
  }

  &:focus {
    border-color: $black;
  }
}

.container {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.75rem;
}

.input {
  width: 6rem;
  border: none;
  margin: 0;
}

.icon {
  place-self: flex-end;
  width: 1.5rem;
  height: 1.5rem;
}

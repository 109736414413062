@import '../../styles/variables.scss';

.container {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.titleInput {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: $border-radius-base;
  padding: 0.5rem 0.75rem;
  margin: 0;

  &:hover {
    border-color: #d1d5db;
  }

  &:focus {
    border-color: $black;
  }
}

.contentInput {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: $border-radius-base;
  padding: 0.5rem 0.75rem;
  margin: 0;

  &:hover {
    border-color: #d1d5db;
  }

  &:focus {
    border-color: $black;
    outline: none;
  }
}

@import '../../styles/variables.scss';

.challengeOverview {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: $font-size-title;
  font-weight: bold;
  margin-bottom: $gap-small;
}

.sectionTitle {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 4rem 3rem;
  grid-template-rows: auto;
  grid-template-areas:
    'banner banner banner sidebar sidebar .'
    'main main main . . .';
  column-gap: $gap-large;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    display: block;
  }
}

.bannerImage {
  grid-area: banner;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin-bottom: $gap-small;
}

.description {
  grid-area: main;
  font-size: $font-size-xlarge;
  font-weight: $font-weight-regular;

  p {
    margin-bottom: 1.5rem;
  }

  img {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: $font-size-large;
    margin-bottom: $gap-regular;
  }
}

.offerList {
  grid-area: sidebar;
  font-size: $font-size-regular;

  p {
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 1rem;
    margin-bottom: $gap-regular;

    li {
      margin-bottom: 0.25rem;
    }
  }
}

.buttonWrapper {
  align-self: flex-start;
}

.ideasContainer {
  display: grid;
  margin-top: 2rem;

  button {
    width: 100%;
    justify-self: center;
    margin-top: 1rem;
  }
}

.link {
  color: #e0015f;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;

  &:hover {
    color: #e0015f;
    opacity: 0.6;
    text-decoration: underline;
  }
}

.closedNotice {
  background-color: #d1d5db;
  box-shadow: 0px 4.972264289855957px 7.4583964347839355px -1.2430660724639893px #0000001a;
  font-family: Poppins;
  font-style: normal;
  text-align: left;

  &:hover {
    opacity: 1;
    box-shadow: auto;
  }
}

.closedTag {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

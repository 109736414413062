@import '../../styles/variables.scss';

.formField {
  display: grid;
  grid-gap: 0.5rem;
  position: relative;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.isPreview {
  border: none;
  padding: 0;
}

.header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}

.trashBinIcon {
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.isRequired {
  color: red;
  font-size: 0.75rem;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 1rem;
  margin-bottom: 0;
}

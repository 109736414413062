@import '../../styles/variables.scss';

.wrapper {
  position: absolute;
  top: $user-navbar-gap;
  left: 0;
  width: 100%;
  background-color: $gray-4;
}

.header {
  padding: 0 $page-horizontal-space-mobile;
  margin-bottom: 0.75rem;
}

.headerTitle {
  color: $hard-text-color;
  font-weight: bold;
}

.section {
  display: grid;
}

.navItem {
  color: $hard-text-color;
  opacity: 0.3;
  padding: 0.75rem $page-horizontal-space-mobile + 1rem;
}

.selected {
  opacity: 1;
}

.signOut {
  border-top: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;
  padding: 1.5rem $page-horizontal-space-mobile + 1rem;
  margin: 0.75rem 0;
}

.withArrowItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subNavItemArrow {
  opacity: 0.3;
  margin-right: $page-horizontal-space-mobile + 1rem;
}

.arrowRightIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: $hard-text-color;
}

.rotate {
  transform: rotate(90deg);
}

@import '../../styles/variables.scss';

.pageTitle {
  font-size: $font-size-title;
  font-weight: bold;
  text-align: center;
}

.pageDescription {
  font-size: $font-size-large;
  margin-top: 1.25rem;
}

.challengeListWrapper {
  margin-top: 2rem;
}

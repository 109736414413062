@import '../../styles/variables.scss';

.content {
  display: flex;
  align-items: center;
  border-radius: $border-radius-large;
  box-shadow: 0px 20px 25px -5px #0000001a;
  padding: 1.5rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  fill: #dc2626;
  background-color: #fee2e2;
  border-radius: $border-radius-round;
  padding: 0.5rem;
}

.title {
  font-size: $font-size-title;
  font-family: Poppins;
  font-weight: bold;
  color: #111827;
  text-align: center;
  color: $hard-text-color;
  margin-top: 1rem;
}

.body {
  font-size: $font-size-regular;
  font-family: Poppins;
  color: #6b7280;
  text-align: center;
  white-space: pre-line;
  padding: 0;
  margin-top: 0.5rem;
}

.footer {
  border: none;
  padding: 0;
  margin-top: 1.25rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.button {
  width: 14rem;
  font-family: Poppins;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 0.375rem;
  padding: 0.5rem 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.publishContent {
  display: flex;
  align-items: center;
  border-radius: $border-radius-large;
  box-shadow: 0px 20px 25px -5px #0000001a;
}

.publishTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  border-radius: $border-radius-large $border-radius-large 0 0;
  padding: 1.6rem 2.4rem;

  svg {
    transform: scale(1.1);
  }

  span {
    padding: 0 0.3rem;
    font-size: $font-size-xlarge;
    font-weight: bold;
    color: #fff;
  }
}

.publishBody {
  margin: 1.5rem 0 0;
  padding: 1rem 3rem;
}

.publishFooter {
  border: none;
  padding: 0;
  margin: 1.5rem 0;

  button {
    padding: 0.5rem 3rem;
    margin: 0 0.5rem;
    border-radius: $border-radius-large;
  }
}

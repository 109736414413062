@import '../../styles/variables.scss';

.languageSwitch {
  text-align: right;

  button {
    border-radius: 8px;
  }
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-header;
  color: $hard-text-color;
  margin-bottom: $gap-regular;
}

.subtitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-xlarge;
  line-height: $gap-middle;
  color: $hard-text-color;
  margin-bottom: $gap-medium;
}

.agreementSection {
  margin-bottom: $gap-medium;
  font-size: $font-size-regular;
}

.sectionTitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-regular;
  line-height: $gap-regular;
  color: $hard-text-color;
  margin-bottom: $gap-small;
}

.longParagraph {
  border: 1px solid $divider-color;
  border-radius: 8px;
  padding: $gap-small;
  font-size: $font-size-regular;
  line-height: $gap-regular;
  margin-bottom: $gap-small;
  max-height: $box-height-regular;
  overflow: scroll;
  white-space: pre-line;
}

.agreeButton {
  margin-right: $gap-medium;
  border-radius: 8px;
}

.declineButton {
  border-radius: 8px;
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.5rem;
  align-items: center;
}

.label {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.5rem;
  align-items: center;
  font-weight: bold;
}

.grayOut {
  color: #9ca3af;
  fill: #9ca3af;
}

.switchLabelIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.questionMarkCircleIcon {
  width: 1.25rem;
  height: 1.25rem;
  fill: #9ca3af;
  cursor: pointer;
}

.switchTooltipContent {
  max-width: 20rem;
  margin: 0;
  padding: 0;
}

@import '../../styles/variables.scss';

// CSS tricks for fluid iframe embed video
// https://css-tricks.com/fluid-width-video/#iframe-video-youtube-vimeo-etc
.wrapper {
  position: relative;
  padding-bottom: 56.25%; // 16:9 ratio
  height: 0;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: $gap-large;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.message {
  display: grid;
  align-items: center;
  grid-gap: 2rem;
}

.header {
  display: grid;
  font-size: 5rem;
  font-weight: bold;
  align-self: flex-end;

  &::after {
    display: block;
    width: 20rem;
    content: '';
    border-top: 0.5rem solid $brand-color;
    margin-top: 1rem;
  }

  @media only screen and (max-width: $min-tablet-portrait-width) {
    font-size: $font-size-header;
    text-align: center;
    align-self: center;

    &::after {
      justify-self: center;
      width: 8rem;
    }
  }
}

.description {
  align-self: flex-start;
  font-size: $font-size-regular;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    font-size: $font-size-small;
    text-align: center;
    align-self: center;
  }
}

.image {
  justify-self: center;
  display: flex;
  width: 100%;
  background-image: url('../../assets/img/yellowMoon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    background-size: contain;
    width: 80%;
    margin: -5rem 0;
  }
}

.icon {
  padding: 5rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    padding: 2rem;
  }
}

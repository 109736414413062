@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  max-width: 50rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.formFields {
  display: grid;
  gap: 2rem;
}

.label {
  font-weight: bold;
  font-size: $font-size-large;
  margin-bottom: $gap-small;
}

.isRequiredIndicator {
  color: $danger-color;
}

.description {
  font-weight: $font-weight-regular;
  font-size: $font-size-regular;
  margin-bottom: $gap-small;
}

.actions {
  justify-self: center;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  margin-top: 3rem;
}

.button {
  width: 10rem;
}

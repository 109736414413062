@import '../../styles/variables.scss';

.userGroupChallenges {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  &__list {
    display: grid;
    grid-gap: 0.25rem;
  }
}

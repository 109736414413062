@mixin font-paragraph {
  font-size: 1.25rem; // 20px
  font-weight: 400; // regular

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: 0.94rem; // 17px
    font-weight: 300; // light
  }
}

@mixin admin-item-wrapper {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
}

@mixin input-wrapper {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: $border-radius-base;
  padding: 0.5rem 0.75rem;
  margin: 0;

  &:hover {
    border-color: #d1d5db;
  }

  &:focus {
    border-color: $black;
  }
}

@mixin rich-text {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  overflow-wrap: anywhere;
  background-color: #ffffff;
  color: #000;

  ul > li,
  ol > li {
    margin: 0.25rem;
  }

  ul,
  ol {
    padding-inline-start: 1.75rem;
  }
}

@import '../../styles/variables.scss';

.wrapper {
  @media only screen and (max-width: $min-tablet-portrait-width) {
    margin-bottom: 2rem;
  }
}

.header {
  font-size: $font-size-large;
  font-weight: medium;
  color: $hard-text-color;
  margin-bottom: 1rem;
}

.ideaOwnerContainer {
  display: flex;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: $border-radius-round;
}

.ideaOwner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: $font-size-regular;
  margin-left: 1rem;
}

.name {
  color: $hard-text-color;
}

@import '../../styles/variables.scss';

.icon {
  width: 2rem;
  height: 2rem;
  fill: $gray-1;
}

.isFocus {
  fill: $blue;
}

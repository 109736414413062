@import '../../styles/variables.scss';

.wrapper {
  position: relative;
  border-radius: 0;
  border: 0;
  box-shadow: $box-shadow-small;
}

.carouselWrapper {
  > div > ol > li {
    background-color: $hard-text-color;
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;

    @media only screen and (max-width: $min-tablet-portrait-width) {
      height: 0.5rem;
      width: 0.5rem;
    }
  }
}

.blobWrapper {
  height: 16.25rem;
}

.blob {
  width: 100% !important;
  height: 16.25rem;
  border-radius: 0;
  object-fit: cover;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: $font-size-xlarge;
  color: $hard-text-color;
  margin-bottom: $gap-small;
}

.description {
  color: $soft-text-color;
  font-size: $font-size-regular;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: $gap-small;
}

.ideaOwnerAndIdeaCommentsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ideaOwnerContainer {
  display: flex;
  align-items: center;
}

.ideaOwnerName {
  color: $hard-text-color;
  margin-left: 1rem;
}

.comment {
  display: flex;
  align-items: center;
}

.chatBubblIcon {
  fill: $hard-text-color;
}

@import '../../styles/variables.scss';

.container {
  display: grid;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.button {
  place-self: end;
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.control {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 1rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;
}

.main {
  grid-column: 1 / 8;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.side {
  grid-column: 8 / 13;
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 1.5rem;
}

.name {
  width: max-content;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.image {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-base;
}

.description {
  margin-top: 0.75rem;
}

.submission {
  @include admin-item-wrapper;
}

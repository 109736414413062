// font-size
$font-size-xheader: 3rem;
$font-size-header: 2rem;
$font-size-title: 1.5rem;
$font-size-xlarge: 1.125rem;
$font-size-large: 1rem;
$font-size-regular: 0.875rem;
$font-size-small: 0.75rem;
$font-size-xsmall: 0.65rem;

// font-weight
$font-weight-medium: 500;
$font-weight-regular: 300;

// size
$page-top-space: 2.5rem;
$page-bottom-space: 5rem;
$page-horizontal-space: 5rem;
$page-horizontal-space-mobile: 1.5rem;
$user-navbar-gap: 5.5rem;
$admin-side-navbar-gap: 18rem;

// gap (maps from most common layout value in Figma)
$gap-xlarge: 5rem; // 80
$gap-large: 3.75rem; // 60
$gap-medium: 2.5rem; // 40
$gap-middle: 1.75rem; // 28
$gap-regular: 1.25rem; // 20
$gap-small: 0.75rem; // 12

// box height
$box-height-regular: 25rem; // 400
$box-height-medium: 37.5rem; // 600

// color (to deprecate)
$primary-color: #2f80ed;
$secondary-color: #373737;
$cookie-blue-color: #2c81ff;
$hard-text-color: #333333;
$soft-text-color: #4f4f4f;
$divider-color: #e0e0e0;
$background-color: #f7f6f3;
$white: #ffffff;
$brand-color: #ffcc00;
$black: #000000;
$danger-color: #dc3545;
$tab-slider-bg: #f7f6f3;
$tab-slider-active: #2f80ed;
$purple: #5d21d1;
$gray: #e5e7eb;
$badge-color-inactive: #333;
$badge-color-active: #ed605a;
$color-invisibility: #6b7280;
$color-phase-txt-initial: #92400e;
$color-phase-txt-folded: #991b1b;
$color-phase-txt-pitched: #065f46;
$color-phase-txt-selected: #1e40af;
$color-phase-bg-initial: #fef3c7;
$color-phase-bg-folded: #fee2e2;
$color-phase-bg-pitched: #d1fae5;
$color-phase-bg-selected: #dbeafe;

// color (new)
$yellow: #fed900;
$blue: #0082fd;
$white: #ffffff;
$black: #000000;
$gray-1: #6b7280;
$gray-2: #d1d5db;
$gray-3: #e5e7eb;
$gray-4: #f9fafb;
$gray-5: #fdfdfd;

// border-radius
$border-radius-base: 0.25rem;
$border-radius-large: 0.5rem;
$border-radius-round: 3rem;

// breakpoints
$max-mobile-portrait-width: 375px + 1px;
$min-tablet-portrait-width: 600px + 1px;
$min-tablet-landscape-width: 960px + 1px;
$min-desktop-width: 1188px + 1px;
$min-wide-desktop-width: 1440 + 1px;
$min-xl-wide-desktop-width: 1920 + 1px;

// z-index
$z-index-modal-overlay: 10000;
$z-index-popup-menu: 1000;

// box-shadow
$box-shadow-large: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-base: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
$box-shadow-small: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);

// text-shadow
$text-shadow-base: 0px 15.3431px 19.1789px rgba(0, 0, 0, 0.1), 0px 7.67154px 7.67154px rgba(0, 0, 0, 0.04);

@import '../../styles/variables.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-image: url('../../assets/img/landingBackground.png');
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    justify-content: flex-start;
    padding: 4rem $page-horizontal-space-mobile;
  }
}

.brandMessageContainer {
  @media only screen and (max-width: $min-tablet-portrait-width) {
    flex: 1;
  }
}

.welcomeText {
  color: $black;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.brandText {
  color: $yellow;
  font-size: 5rem;
  margin-top: -1.25rem;
}

.description {
  color: $black;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.button {
  float: right;
  min-width: 8rem;
  margin-top: 1rem;
}

.inputContainer {
  width: 100%;
}

.emailInput {
  width: 100%;
  border: 1px solid $gray-1;
  box-sizing: border-box;
  border-radius: $border-radius-large;
  padding: 0.75rem 1rem;

  &:hover,
  &:focus-within {
    border: 1px solid $black;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './styles/variables.scss';

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: light;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: 'Poppins', 'Roboto', sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.w-md-editor-text {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: inherit;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: inherit;
}

.rpt-arrow {
  display: none;
}

.rpt-shadow-container {
  border-radius: 8px !important;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;
}

.rpt-textbox {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  width: 200px;
}

.rpt-textbox-container,
.rpt-shadow-container {
  width: 200px !important;
}

.fsp-picker {
  z-index: 1000 !important;
}

.indentTab {
  margin-left: 1rem;
}

.react-cookie-law-dialog {
  max-width: 1280px;
  margin: auto;
  border-radius: 0.875rem;
  box-shadow: 0rem 1rem 2rem rgba(65, 64, 64, 0.3);
  top: auto !important;
  bottom: 0.875rem !important;
  right: 0.875rem !important;
  left: 0.875rem !important;
  background-color: $white !important;
  padding: 0.9rem 1.5rem 1.75rem !important;
  border-top: 1px solid $divider-color;
  overflow: hidden;
}

.react-cookie-law-container {
  max-width: none !important;
  display: flex;
}

.react-cookie-law-select-pane {
  padding: 3px 0 !important;
}

.react-cookie-law-option-wrapper {
  margin-left: 0 !important;
  margin-right: $gap-regular !important;

  label {
    color: $soft-text-color !important;
    opacity: 0.6;
  }
}

.react-cookie-law-msg {
  color: #000 !important;
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
}

.react-cookie-law-policy {
  text-decoration: none !important;
  color: $cookie-blue-color !important;
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
  margin-left: 0 !important;
  position: absolute;
  left: 1.5rem;
  bottom: 1rem;
}

.react-cookie-law-button-wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  margin-top: 1rem;
}

.react-cookie-law-accept-all-btn {
  background-color: #000 !important;
  border: none !important;
  border-radius: 0.75rem !important;
  color: $white !important;
  padding: 1rem 1.5rem !important;
  font-size: 1rem !important;

  &:hover {
    opacity: 0.6;
  }
}

.react-cookie-law-select-pane {
  display: none !important;
}

.user-idea {
  background-color: #ebebfa;
}

.idea-nav {
  padding-bottom: 20px;
}

.idea-title {
  margin-top: 15px;
}

.idea-author {
  height: 40px;
  padding-top: 3px;
  font-size: 14px;
}

.idea-comments-box {
  height: 54px;
  padding-top: 13px;
}

.idea-image {
  max-width: 100%;
  max-height: 100%;
}

.idea-card {
  margin: 15px 15px 0px 15px;
}

.idea-detail-section {
  padding-top: 25px;
}

.idea-detail-section:last-of-type {
  margin-bottom: 100px;
}

footer {
  background-color: #767699;
  height: 100px;
}

.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}

.footer {
  padding-top: 20px;
}
.footer textarea {
  margin-right: 10px;
}

.input-group-btn {
  color: #d2e6ea;
}

.comment-content {
  background-color: #e0e0e0;
  padding: 10px 5px 10px 15px;
  margin: 15px 0px 0px 0px;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: none;
}

.admin-container {
  background-color: #f7f5f2;
  background-color: white;
}

.admin-container h1 {
  font-size: 24px;
}

.admin-container h2 {
  font-size: 14px;
  color: #828282;
  font-weight: bold;
}

.admin-container {
  font-size: 14px;
}

.admin-image {
  width: 50px;
  height: 50px;
}

.admin-leftnav {
  background-color: #f7f5f2;
  border-right: 1px solid #e0e0e0;
  padding-top: 48px;
}

.admin-subnav {
  padding-top: 48px;
  background-color: #f7f5f2;
}

.admin-subnav h1 {
  padding-left: 40px;
}

.admin-subnav .list-group {
  width: 100%;
}

.admin-subnav .list-group-item {
  background-color: #f7f5f2;
  border: none;
}

.admin-subnav .list-group-item:hover {
  background-color: #f8f9fa;
}

.admin-subnav .list-group-item:active {
  outline: none !important;
  box-shadow: none;
}

.admin-subnav .list-group-item.selected {
  border-bottom: 0px;
  width: 100%;
  border-right: 5px solid #2f80ed;
  background-color: white;
  outline: none;
}

/* Admin Content */
.admin-content {
  box-shadow: -10px 0 18px -12px rgba(0, 0, 0, 0.25);
  padding-left: 40px;
  padding-right: 40px;
}

.admin-content .list-group-item {
  padding: 20px 0px 20px 0px;
}

.admin-rightpane {
  padding-left: 40px;
  padding-top: 48px;
  background-color: white;
}

.admin-content .admin-header {
  padding-bottom: 35px;
}

.admin-rightpane .card {
  margin-top: 20px;
}

.admin-rightpane .card-title {
  font-size: 14px;
}

.admin-rightpane .profile {
  padding-top: 10px;
  padding-bottom: 30px;
}

.admin-rightpane .bio-text {
  padding-top: 3px;
}

.form-control {
  border: 0;
}

.admin-content .memo-subheading {
  padding: 15px 0px 40px 0px;
}

.memo-count {
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.admin-content .memo-template {
  text-align: center;
  width: 92px;
  height: 104px;
  background-color: white;
  border-color: #e0e0e0;
}

.admin-content .memo-template:active {
  outline: none !important;
  box-shadow: none;
}

.memo-template:hover {
  outline: none !important;
  box-shadow: none;
}

.memo-listgroup p {
  color: #828282;
}

.memo-listgroup h3 {
  font-size: 13px;
  color: #2f80ed;
  font-weight: bold;
}

button:focus,
button:active {
  outline: none !important;
  box-shadow: none;
}

.modal-content {
  border: 0 none;
  border-radius: 0;
}

.modal-body {
  overflow-y: auto;
}

input:focus {
  outline: none !important;
  box-shadow: none;
}

.form-control:focus {
  border-style: none;
  box-shadow: none;
}

.bm-menu-wrap {
  top: 0;
  position: absolute;
  height: 100vh;
  min-width: 20rem;
}

.bm-burger-button {
  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.bm-cross-button {
  width: 1rem !important;
  height: 1.5rem !important;
  right: 1.2rem !important;
  top: 2.5rem !important;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.bm-item-list {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: $min-tablet-portrait-width) {
  .react-cookie-law-dialog {
    padding: 0.875rem !important;
  }

  .react-cookie-law-container {
    display: block;
  }

  .react-cookie-law-policy {
    position: inherit;
    display: flex;
    justify-content: center;
  }

  .react-cookie-law-button-wrapper {
    display: block;
    width: 100%;
    text-align: center;
  }
}

@import '../../styles/variables.scss';

.adminIdeas {
  display: grid;

  button {
    width: 100%;
    justify-self: center;
    margin-top: 1rem;
  }
}

.ideaColumn {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 25ch;
  grid-gap: 1rem;
  align-items: center;
}

.image {
  width: 10rem;
  aspect-ratio: 16 / 9;
  border-radius: 0.5rem;
}

.nameAndDescription {
  text-align: left;
  width: 100%;
  display: grid;

  &:hover {
    text-decoration: underline;
  }
}

.name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.phase {
  border-radius: 0.8rem;
  padding: 0.15rem 1rem;
}

.INITIAL {
  color: $color-phase-txt-initial;
  background-color: $color-phase-bg-initial;
}

.FOLDED {
  color: $color-phase-txt-folded;
  background-color: $color-phase-bg-folded;
}

.PITCHED {
  color: $color-phase-txt-pitched;
  background-color: $color-phase-bg-pitched;
}

.SELECTED {
  color: $color-phase-txt-selected;
  background-color: $color-phase-bg-selected;
}

@import '../../styles/variables.scss';

.labelContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.1rem;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
}

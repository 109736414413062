@import '../../styles/variables.scss';

.container {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sections {
  display: grid;
  grid-gap: 1rem;
}

.sectionHeader {
  font-size: 1rem;
  font-weight: bold;
}

.sectionParagraph {
  font-size: 1rem;
  margin-bottom: 0;
}

@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  width: $admin-side-navbar-gap;
  height: 100vh;
  background-color: $background-color;
  z-index: 1000;
}

.nav {
  display: grid;
  grid-gap: 2rem;
  padding: 2rem 0 2rem 2rem;
}

.logo {
  width: 5rem;
}

.adminLinks {
  display: grid;
  grid-gap: 2rem;
  margin-top: 1rem;
}

.adminLink {
  display: block;
  font-size: 0.95rem;
  font-weight: bold;
  color: #9ca3af;
}

.adminLinkActive {
  font-weight: bold;
  color: #000000;

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 30%;
    border-bottom: 3px solid #000;
    border-radius: 1rem;
  }
}

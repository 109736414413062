@import '../../styles/variables.scss';

.wrapper {
  width: 100vw;
  height: 90vh;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand {
  color: $brand-color;
  font-size: $font-size-title;
  font-weight: bold;
  margin: 1rem;
}

.messageTitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-header;
  padding: $gap-regular;
}

.actionButtons {
  margin: 60px 0 30px;
}

.smallMessage {
  font-size: $font-size-small;
  padding: $gap-regular;
}

.privacyLink {
  font-weight: $font-weight-medium;
}

.agreeButton {
  margin-right: $gap-large;
  border-radius: 8px;
  background-color: $primary-color;
}

.declineButton {
  border-radius: 8px;
}

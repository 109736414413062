@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-gap: 1.5rem;
  margin-top: 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.actionButtons {
  display: flex;
  gap: 1rem;
}

.actionButton {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.description {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sections {
  display: grid;
  grid-gap: 1rem;
}

.sectionHeader {
  font-size: 1rem;
  font-weight: bold;
}

.sectionParagraph {
  font-size: 1rem;
  margin-bottom: 0;
}

.title {
  margin: 1rem 0;
  font-size: $font-size-title;
  font-weight: bold;
}
@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-gap: 1.5rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #f7f6f3;
}

.control {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.button {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.feedback {
  opacity: 0;
  transition: ease-in-out opacity 0.15s;
  font-weight: bold;
  color: $secondary-color;
}

.submitted {
  opacity: 1;
}
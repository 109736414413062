@import '../../styles/variables.scss';

.textarea {
  width: 100%;
  border: 1px solid $divider-color;
  border-radius: $border-radius-large;
  overflow: hidden;
  padding: 0.5rem 0.75rem;

  &:hover,
  &:focus-within {
    border: 1px solid $primary-color;
  }
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  width: 100%;
  background-color: $black;
  padding: 2rem $gap-xlarge;

  border-top: 1px solid $gray-1;
}

.container {
  font-size: 0.85rem;
  justify-self: right;
  display: grid;
  color: $white;
  grid-auto-flow: column;
  column-gap: 1rem;
  margin-right: 1rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    justify-self: center;
  }
}

.link {
  color: $white;

  &:hover {
    color: $white;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray-1;
}

@import '../../styles/variables.scss';

.userGroupColumn {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
}

.name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@import '../../styles/variables.scss';

.wrapper {
  height: max-content;
  margin-top: 1rem;
}

.container {
  display: grid;
  grid-gap: 1rem;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
}

.paragraph {
  font-size: 1rem;
  margin-bottom: 0;
}

@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-index-modal-overlay + 1;
}

.container {
  display: flex;
  align-items: center;
  background-color: #fbbf24;
  box-shadow: 0px 4px 4px 0px #00000020;
  border-radius: $border-radius-large;
  padding: 0.5rem 1rem;
}

.text {
  color: #374151;
}

.dismissButton {
  background-color: white;
  border: none;
  border-radius: $border-radius-base;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

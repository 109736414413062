@import '../../styles/variables.scss';

.wrapper {
  flex-shrink: 0;
  width: 100%;
  background-color: $black;
  padding: 2rem 0;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    padding: 3rem 0;
  }
}

.container {
  display: flex;
  padding: 0 $gap-xlarge;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    flex-direction: column;
    padding-left: $page-horizontal-space-mobile;
    padding-right: $page-horizontal-space-mobile;
  }
}

.mailChimpSubscribeForm {
  display: flex;
  flex-direction: column;
  margin-right: $gap-xlarge * 2;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    margin-right: 0;
    margin-bottom: $gap-medium;
  }
}

.inputActionContainerStyle {
  width: 100%;
}

.emailInput {
  width: 28rem;
  border: none;
  border-radius: $border-radius-large;
  padding: 0.65rem 0 0.65rem 1rem;
  margin-bottom: $gap-regular;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    width: 100%;
  }
}

.learnMore {
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: bold;
  color: $white;
  margin-bottom: $gap-regular;
}

.subscribeButton {
  background-color: $white;
  border: none;
  font-size: 0.85rem;
  border-radius: 12px;
  color: $black;
  padding: 0.75rem 3rem;
  transition-duration: 0.2s;

  &:hover {
    opacity: 0.9;
  }

  @media only screen and (max-width: $min-tablet-portrait-width) {
    width: 100%;
  }
}

.learnMoreButton {
  padding: 0.75rem 3rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    width: 100%;
  }
}

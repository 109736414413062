@import '../../styles/variables.scss';

.searchButton {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sectionTitle {
  font-size: $font-size-title;
  font-weight: bold;
}

.input {
  margin: 1.0rem 0 0 0;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.switchRow {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin: 1rem 0;
}

.filters {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #f7f6f3;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.advanced {
  padding: 0.5rem 1.5rem;
}

.date {
  margin: 1rem 0;
}
@import '../../styles/variables.scss';

.wrapper {
  position: relative;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.badge {
  position: absolute;
  top: -0.3rem;
  right: -0.6rem;
  background-color: $badge-color-inactive;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.7rem;
}

.active {
  background-color: $badge-color-active;
}

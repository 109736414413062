@import '../../styles/variables.scss';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: grid;
  justify-items: center;
}

.input {
  display: block;
  width: 20rem;
  padding: 0.25rem 0.5rem;
}

.button {
  width: 20rem;
  color: white;
  background-color: #fbbf24;
  border: none;
  border-radius: $border-radius-base;
  padding: 0.25rem 0.75rem;
  margin-top: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
}

@import '../../styles/variables.scss';

.memos {
  display: grid;

  &__container {
    display: grid;
    row-gap: 1rem;
  }

  &__emptyState {
    border-radius: 1rem;
    box-shadow: $box-shadow-base;
    padding: 1rem;
    overflow: hidden;
  }

  button {
    justify-self: center;
    margin-top: 1rem;
  }
}

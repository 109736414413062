@import '../../styles/variables.scss';

.modalDialog {
  max-width: 50%;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    max-width: 100%;
  }
}

.modalContent {
  border-radius: $border-radius-large;
  background-size: cover;
}

.container {
  position: relative;
  padding: $gap-large;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    padding: 2.5rem $page-horizontal-space-mobile 1.75rem;
  }
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1rem;
  height: 1rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    top: 1rem;
    right: 1rem;
  }
}

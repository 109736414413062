@import '../../styles/variables.scss';

.content {
  display: flex;
  align-items: center;
  border-radius: $border-radius-large;
  box-shadow: 0px 20px 25px -5px #0000001a;
  padding: 1.5rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  fill: #dc2626;
  background-color: #fee2e2;
  border-radius: $border-radius-round;
  padding: 0.5rem;
}

.title {
  font-size: $font-size-title;
  font-family: Poppins;
  font-weight: bold;
  color: #111827;
  text-align: center;
  color: $hard-text-color;
  margin-top: 1rem;
}

.body {
  font-size: $font-size-regular;
  font-family: Poppins;
  color: #6b7280;
  text-align: center;
  white-space: pre-line;
  padding: 0;
  margin-top: 0.5rem;
}

.footer {
  display: grid;
  grid-auto-flow: column;
  border: none;
  padding: 0;
  margin-top: 1.25rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.button {
  width: 14rem;
  font-family: Poppins;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 0.375rem;
  padding: 0.5rem 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.cancel {
  color: #374151;
  border: 1px solid #d1d5db;
  transition: 0.2s ease-in-out;

  &:hover {
    border: 1px solid #d1d5db;
  }
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
}

.container {
  margin-top: 2rem;
}

.createChallengeLink {
  justify-self: flex-end;
}

.createChallengeButton {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

@import '../../styles/variables.scss';

.form {
  display: grid;
  grid-gap: 0.75rem;
}

.addFormFieldButton {
  place-self: end;
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1.5rem;
}

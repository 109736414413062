@import '../../styles/variables.scss';

.wrapper {
  width: 100%;
  background-size: cover;
  background-position: center;
  aspect-ratio: 4 / 1;
  box-shadow: $box-shadow-base;
  border-radius: 1rem;
}

.container {
  height: 100%;
  display: grid;
  align-items: flex-end;
  padding: 1rem;
}

.title {
  text-shadow: rgba(50, 50, 50, 0.5) 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
}

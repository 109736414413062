@import '../../styles/variables.scss';

.ideasGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.75rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 1rem;
  }

  @media only screen and (min-width: $min-xl-wide-desktop-width) {
    grid-template-columns: repeat(4, 1fr);
  }
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.otherIdeas {
  margin-top: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: $black;
    margin-bottom: 1rem;
  }

  &__ideas {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (min-width: $min-xl-wide-desktop-width) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: $min-tablet-landscape-width) {
      grid-template-columns: 1fr;
    }
  }
}

@import '../../styles/variables.scss';

.container {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

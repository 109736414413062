@import '../../styles/variables.scss';

.challengeOverview {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: $font-size-title;
  font-weight: bold;
  margin-bottom: $gap-small;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 4rem 3rem;
  grid-template-rows: auto;
  grid-template-areas:
    'banner banner banner sidebar sidebar .'
    'main main main . . .';
  column-gap: $gap-large;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    display: block;
  }
}

.bannerImage {
  border-radius: $border-radius-large;
  grid-area: banner;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin-bottom: $gap-small;
}

.description {
  grid-area: main;
  font-size: $font-size-xlarge;
  font-weight: $font-weight-regular;

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: $font-size-large;
    margin-bottom: $gap-regular;
  }
}

.offerList {
  grid-area: sidebar;
  font-size: $font-size-regular;

  p {
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 1rem;
    margin-bottom: $gap-regular;

    li {
      margin-bottom: 0.25rem;
    }
  }
}

.buttonWrapper {
  align-self: flex-start;
}

@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $user-navbar-gap;
  background-color: $gray-4;
  box-shadow: $box-shadow-base;
  z-index: 1000;
  padding-left: $page-horizontal-space;
  padding-right: $page-horizontal-space;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    padding-left: $page-horizontal-space-mobile;
    padding-right: $page-horizontal-space-mobile;
  }
}

.container {
  height: 100%;
}

.containerDesktop {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    display: none;
  }
}

.mainNavigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subNavigation {
  display: flex;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    &:not(:last-child) {
      margin-right: $gap-regular;
    }
  }
}

.ideasAndProfileContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1rem;
  align-items: flex-end;
}

.userIconContainer {
  position: relative;
}

.profileAvatarWrapper {
  cursor: pointer;
}

.navItem {
  color: $hard-text-color;
  opacity: 0.3;
}

.activeNavItem {
  opacity: 1;
}

.brand {
  color: $brand-color;
  font-size: $font-size-title;
  font-weight: bold;
}

.userIcon {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.containerMobile {
  display: none;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

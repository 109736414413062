@import '../../styles/variables.scss';

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.control {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 1rem;
}

.headerContainer {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.header {
  font-weight: bold;
}

.evaluator {
  font-size: 1.25rem;
  font-style: italic;
  margin-top: 0.5rem;
}

.ideaName {
  font-size: 2rem;
  color: #f9c623;
}

@import '../../styles/variables.scss';

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-xheader;
  color: $hard-text-color;
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
  color: $cookie-blue-color;

  &:hover {
    color: $cookie-blue-color;
  }
}

.switch {
  display: inline-block;
}

.titleWrapper {
  width: 100%;
  padding-top: 0.25rem;
}

.optionTitle {
  display: inline-block;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
}

.backButton {
  width: 8rem;
  background: #373737;
  border: 2px solid #373737;
  border-radius: $border-radius-base;
  color: $white;
  padding: 0.875rem 1.5rem;
  margin-right: 2rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

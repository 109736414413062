@import '../../styles/variables.scss';

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: $border-radius-round;
}

.placeholder {
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $hard-text-color;
  border-radius: $border-radius-round;
  color: $hard-text-color;
}

.small {
  width: 2rem;
  height: 2rem;
}

.large {
  width: 4rem;
  height: 4rem;
}

@import '../../styles/variables.scss';

.userIdeasPage {
  display: grid;

  button {
    width: 100%;
    justify-self: center;
    margin-top: 1rem;
  }
}

.title {
  font-size: $font-size-title;
  margin-bottom: $gap-small;
  font-weight: bold;
}

.pageTabs {
  position: relative;
  padding: 0;
  list-style: none;
  margin-bottom: $gap-small;

  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.tab {
  font-size: $font-size-regular;
  line-height: 1.8rem;
  text-align: center;
  color: $hard-text-color;
  float: left;
  display: block;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  width: 8.75rem;
  cursor: pointer;
}

.tabSlider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: $tab-slider-bg;
  z-index: -1;
}

.activeTab {
  border-bottom: 0.25rem solid $tab-slider-active;
}

.ideaCardWrapper {
  margin-bottom: 1.875rem !important;
}

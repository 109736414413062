@import '../../styles/variables.scss';

.container {
  margin: 1rem 0;
  padding: 0 0.5rem;
}

.userShortColumn {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
}

.name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.email {
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.warning {
  font-size: 0.9rem;
  color: red;
}

.userLink {
  font-size: 0.9rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  margin: 0.25rem;
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #fff;
  transition: border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    border-color: #000000;
  }
}
.container {
  display: grid;
  grid-gap: 1.5rem;
  margin-top: 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.control {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.button {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

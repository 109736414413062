@import '../../styles/variables.scss';

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  margin-top: 5.5rem;
}

@import '../../styles/variables.scss';

.wrapper {
  float: right;
  position: relative;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-items: flex-end;
  align-items: flex-end;
}

.iconWrapper {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: $border-radius-round;
  cursor: pointer;

  transition: background-color 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  &:hover {
    background-color: rgba(156, 163, 175, 0.9);
  }
}

.isActive {
  background-color: rgba(156, 163, 175, 0.9);
}

.icon {
  width: 100%;
  height: 100%;
}

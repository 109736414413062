@import '../../styles/variables.scss';

.wrapper {
  max-width: 800px;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.header {
  font-weight: bold;
  font-size: $font-size-title;
}

.sections {
  display: grid;
  grid-gap: $gap-medium;
}

.descriptions {
  display: grid;
  grid-gap: $gap-small;
}

.paragraph {
  font-size: $font-size-xlarge;
  font-weight: $font-weight-regular;
  margin-bottom: 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: $font-size-large;
  }
}

.formFields {
  display: grid;
  gap: 4rem;
}

@import '../../styles/variables.scss';

.memo {
  position: relative;
  display: grid;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__profile {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__user {
    display: grid;
  }

  &__emailAddress {
    font-size: 0.75rem;
    color: #6b7280;
  }

  &__content {
    margin: 1rem 0;
    color: #6b7280;
  }

  &__options {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__optionsMenu {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: max-content;
    background-color: $white;
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-base;
    padding: 0.75rem;
    z-index: $z-index-popup-menu;

    &:hover {
      color: $white;
      background-color: $danger-color;
    }
  }

  &__createdAt {
    place-self: end;
  }

  svg {
    cursor: pointer;
  }
}

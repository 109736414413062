@import '../../styles/variables.scss';

.phase {
  position: relative;
  border-radius: 0.8rem;
  padding: 0.15rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.INITIAL {
  color: $color-phase-txt-initial;
  background-color: $color-phase-bg-initial;
}

.FOLDED {
  color: $color-phase-txt-folded;
  background-color: $color-phase-bg-folded;
}

.PITCHED {
  color: $color-phase-txt-pitched;
  background-color: $color-phase-bg-pitched;
}

.SELECTED {
  color: $color-phase-txt-selected;
  background-color: $color-phase-bg-selected;
}

.downarrow {
  font-size: 0.8rem;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: 0.5rem;

  &:before {
    content: '\276F';
  }
}

.dropdownWrapper {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $white;
  box-shadow: $box-shadow-small;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  z-index: 10;
  cursor: initial;
}

.dropdownContainer {
  display: grid;
  align-items: center;
  gap: 0.6rem;
  margin-top: 0.6rem;
}

.item {
  cursor: pointer;
  background-color: $white;
}

@import '../../styles/variables.scss';

.container {
  display: grid;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.button {
  place-self: end;
  margin-top: 1rem;
}

@import '../../styles/variables.scss';

.challengeColumn {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 25ch;
  grid-gap: 1rem;
  align-items: center;
}

.image {
  width: 10rem;
  aspect-ratio: 16 / 9;
  border-radius: 0.5rem;
}

.titleAndContent {
  text-align: left;
  width: 100%;
  display: grid;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content {
  max-height: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  :global .wmde-markdown {
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: normal;
    border: none;
    padding: 0;
    margin: 0;
  }

  // TODO: Require a new interface or database design
  // The current markdown preview breaks the interface
  ul,
  ol,
  iframe,
  a {
    display: none;
  }
}

.tooltipContent {
  max-width: 20rem;
}

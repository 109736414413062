@import '../../styles/variables.scss';

.publicIdeasPage {
  &__title {
    font-size: $font-size-title;
    margin-bottom: $gap-small;
    font-weight: bold;
  }

  &__emptyStateWrapper {
    margin-top: 2rem;
  }

  &__ideas {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.75rem;

    @media only screen and (max-width: $min-tablet-portrait-width) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-gap: 1rem;
    }

    @media only screen and (min-width: $min-xl-wide-desktop-width) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  button {
    width: 100%;
    justify-self: center;
    margin-top: 1.75rem;
  }
}

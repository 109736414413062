@import '../../styles/variables.scss';

.container {
  display: grid;
  height: max-content;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  padding: 1rem;
  overflow: hidden;
}

.controls {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
}

.previewLink {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
}

.sections {
  display: grid;
}

.paragraph {
  font-size: 1rem;
  margin-bottom: 0;
}

.formFields {
  display: grid;
  grid-gap: 1rem;
  margin-top: 1rem;
}

@import '../../styles/variables.scss';

.userInfoPage {
  width: 100%;
  padding-bottom: 8rem;
}

.title {
  font-size: $font-size-header;
  font-weight: bold;
  margin-bottom: 2rem;
}

@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content auto min-content;
}

.steps {
  display: grid;
  grid-auto-flow: column;
}

.step {
  display: grid;
  grid-auto-flow: row;
  justify-self: center;
  justify-items: center;
  gap: 0.25rem;
}

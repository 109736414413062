@import '../../styles/variables.scss';

.title {
  font-size: 1.5rem;
  margin: 0;
}

.shortDescription {
  font-size: 1rem;

  margin: 0;
  margin-top: 0.5rem;
}

.longDescription {
  font-size: 1rem;

  margin: 0;
  margin-top: 0.5rem;
}

@import '../../styles/variables.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-image: url('../../assets/img/landingBackground.png');
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    justify-content: flex-start;
    padding: 4rem $page-horizontal-space-mobile;
  }
}

.welcomeText {
  color: $hard-text-color;
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
}

.brandText {
  color: $brand-color;
  font-size: 5rem;
  margin-top: -1.25rem;
}

.description {
  font-size: $font-size-header;
  color: $hard-text-color;
  margin-top: -0.5rem;
}

.smallMessage {
  font-size: $font-size-regular;
  color: $soft-text-color;
  margin-top: 50px;
}

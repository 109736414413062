@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  gap: $gap-small;
}

.userBasicInfo {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  grid-gap: 1rem;
}

.name {
  font-weight: bold;
}

.role,
.organization {
  color: $gray-1;
  font-size: $font-size-small;
}

.bio {
  color: $gray-1;
}

.info {
  color: $gray-1;
  font-size: $font-size-small;

  :last-child {
    font-size: 1rem;
  }
}

.ideaSubmitted {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.5rem;
  align-items: center;
  font-weight: $font-weight-medium;
  margin-top: 0.25rem;
}

@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-gap: 1rem;
}

.field {
  > h3,
  p {
    font-size: 1rem;
  }

  > p {
    margin: 0;
  }
}

.label {
  font-weight: bold;
}

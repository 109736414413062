@import '../../styles/variables.scss';

.label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.imagePreviewContainer {
  position: relative;
  width: fit-content;
  margin-bottom: 1rem;
}

.imagePreview {
  width: 20rem;
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-base;
}

.closeIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  padding: 0.25rem;
  background-color: white;
  border-radius: $border-radius-round;
  box-shadow: 0px 1px 4px 0px #00000040;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@import '../../styles/variables.scss';

.defaultChallengeDetailPage {
  h1 {
    font-size: $font-size-title;
    font-weight: bold;
    margin-bottom: $gap-small;
  }

  &__closedTag {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 4rem 3rem;
    grid-template-rows: auto;
    grid-template-areas:
      'banner banner banner sidebar sidebar .'
      'main main main . . .';
    column-gap: $gap-large;

    @media only screen and (max-width: $min-tablet-landscape-width) {
      display: block;
    }
  }

  &__bannerImage {
    grid-area: banner;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    margin-bottom: $gap-small;
    border-radius: $border-radius-large;
    overflow: hidden;
  }

  &__description {
    grid-area: main;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-regular;

    p {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: $min-tablet-landscape-width) {
      font-size: $font-size-large;
      margin-bottom: $gap-regular;
    }
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__startSubmissionButton {
    align-self: flex-start;
  }

  &__closedNotice {
    background-color: #d1d5db;
    box-shadow: $box-shadow-base;
    text-align: left;

    &:hover {
      opacity: 1;
      box-shadow: auto;
    }
  }

  &__ideas {
    display: grid;
    margin-top: 2rem;

    button {
      width: 100%;
      justify-self: center;
      margin-top: 1rem;
    }
  }
}

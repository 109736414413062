@import '../../styles/variables.scss';

.icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: #9ca3af;
  cursor: pointer;
}

.tooltipContent {
  max-width: 20rem;
}

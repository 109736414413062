@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.titleLine {
  margin-bottom: $gap-medium;
}

.challengeTitle {
  font-weight: bold;
  font-size: $font-size-xlarge;
}

.sectionTitle {
  font-weight: bold;
  font-size: $font-size-xlarge;
  margin-bottom: $gap-small;
}

.sectionDescription {
  font-size: $font-size-xlarge;
  font-weight: $font-weight-regular;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: $font-size-large;
  }
}

.previewArea {
  background-color: $background-color;
  height: fit-content;
  padding: $gap-regular;

  .caption {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: $gap-small;
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    margin-bottom: $gap-regular;
  }
}

.leftPart {
  padding-right: 25px;
}

.ideaCard {
  border-radius: 0;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;

  .noRadius {
    border-radius: 0;
  }
}

.cardTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 143.8%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #333333;
}

.cardDescription {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
}

.avatarContainer {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.userName {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-left: 12px;
}

.addMoreIcon {
  margin-top: 37px;
  position: relative;
}

.ideaCreationArea {
  margin-top: $gap-medium;
}

.questionContainer {
  margin-bottom: $gap-medium;
}

.questionTitle {
  font-weight: bold;
  font-size: $font-size-large;
  margin-bottom: $gap-small;
}

.questionDescription {
  font-weight: $font-weight-regular;
  font-size: $font-size-regular;
  margin-bottom: $gap-small;

  input {
    width: 100%;
  }
}

.uploadBtnContainer {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  margin-bottom: $gap-xlarge;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    flex-direction: column;
    margin-bottom: $gap-regular;
  }
}

.uploadButton {
  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
    margin-bottom: 0.25rem;
  }
}

.imageNameContainer {
  display: flex;
}

.imageName {
  font-size: $font-size-regular;
  color: $hard-text-color;
  max-width: 9.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0 0.5rem;
}

.textInput {
  @include input-wrapper;
}

.textAreaInput {
  @include input-wrapper;

  &:focus-visible {
    outline: none;
  }
}

.hasHoverEffect {
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.5;
  }
}

.buttonArea {
  display: flex;
}

.saveButton {
  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.submitButton {
  margin-left: 1rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

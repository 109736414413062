@import '../../styles/variables.scss';

.container {
  position: relative;
}

.optionsWrapper {
  background-color: white;
  position: absolute;
  right: 0;
  top: 3rem;
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 1rem 1.25rem;
}

.optionsContainer {
  position: relative;
  display: grid;
  grid-auto-columns: max-content;
  grid-gap: 0.25rem;
}

.indicator {
  position: absolute;
  font-size: 1.5rem;
  top: -1.75rem;
  right: -1rem;
}

.item {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

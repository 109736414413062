@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-gap: 1.5rem;
  margin-top: 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.control {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.editForm {
  grid-column: 1 / -1;
}

.button {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.permanentlyDelete {
  color: #ef4444;
  font-weight: bold;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}

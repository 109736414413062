@import '../../styles/variables.scss';

.wrapper {
  display: grid;
}

.main {
  display: grid;
}

.isPitchedLayout {
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    display: block;
  }
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}

.postedAt {
  font-size: 0.85rem;
  color: $gray-1;
}

.image {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-base;
}

.description {
  margin-top: 1rem;
}

.callout {
  color: white;
  background-color: #a2a3a7;
  padding: 1rem;
  border-radius: $border-radius-large;
  margin: 1rem 0;
}

@import '../../styles/variables.scss';

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  margin-left: $admin-side-navbar-gap;
  margin-top: 2rem;
  padding: 0 2rem;
  padding-bottom: 5.5rem;
}

@import '../../styles/variables.scss';

.hookFormTextAreaInput {
  label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  textarea {
    display: block;
    width: 100%;
    font-size: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: $border-radius-base;
    padding: 0.5rem 0.75rem;
    margin: 0;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: #d1d5db;
    }

    &:focus {
      border-color: $black;
    }
  }
}

@import '../../styles/variables.scss';

.title {
  font-size: 1.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1rem;
  text-align: center;
}

.image {
  display: grid;
  place-content: center;
}

.icon {
  max-width: 40rem;
  width: 100%;
  height: 100%;
}

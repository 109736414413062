@import '../../styles/variables.scss';

.wrapper {
  padding: 3rem 8rem 3rem 1.5rem;
}

.controlPanel {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.backButtonContainer {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.headerContainer {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.header {
  font-weight: bold;
}

.ideaName {
  font-size: 2rem;
  color: #f9c623;
}

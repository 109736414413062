@import '../../styles/variables.scss';

.wrapper {
  width: 100vw;
  height: 90vh;
}

.container {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand {
  color: $brand-color;
  font-size: $font-size-title;
  font-weight: bold;
  margin: 1rem;
}

.messageTitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-title;
}

.messageText {
  font-size: $font-size-large;
}

.emailLink {
  font-weight: $font-weight-medium;
}

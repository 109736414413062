@import '../../styles/variables.scss';

.default {
  border: none;
  border-radius: $border-radius-base;
  color: $white;
  padding: 0.5rem 1.5rem;
  transition: 0.2s;

  &:hover {
    opacity: 0.9;
  }
}

.primaryButton {
  background-color: $primary-color;
}

.secondaryButton {
  background-color: $secondary-color;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border: none;
  color: #ffffff;
  fill: #ffffff;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  transition: all 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  box-shadow: 0px 1.3157894611358643px 2.6315789222717285px 0px #0000000d;

  font-family: Poppins;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.25rem;
  font-weight: 400;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled {
    background-color: #9ca3af !important;
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

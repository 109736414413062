@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  grid-gap: 1.5rem;
}

.container {
  display: grid;
  grid-template-columns: 5rem auto;
  grid-gap: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: $border-radius-large;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 1rem 4rem 1rem 1.75rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

.image {
  place-self: center;
  width: 100%;
  border-radius: $border-radius-large;
}

.contentWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 21rem auto 10rem;
  grid-gap: 1rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 0.75rem;
  }
}

.title {
  font-weight: bold;
  text-align: left;
  font-size: 1.5rem;
  margin: 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: 1.25rem;
    text-align: center;
  }
}

.content {
  text-align: left;
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  :global .wmde-markdown {
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    font-size: 1rem;
    font-weight: normal;
    border: none;
    padding: 0;
    margin: 0;
  }

  // TODO: Require a new interface or database design
  // The current markdown preview breaks the interface
  ul,
  ol,
  iframe,
  a {
    display: none;
  }

  @media only screen and (max-width: $min-tablet-portrait-width) {
    text-align: center;
  }
}

.cta {
  width: 12rem;

  @media only screen and (max-width: $min-tablet-portrait-width) {
    width: 100%;
  }
}

.greyOut {
  background-color: #e3e3e3;
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.titleLine {
  margin-bottom: $gap-medium;
}

.challengeTitle {
  font-weight: bold;
  font-size: $font-size-title;
}

.sectionTitle {
  font-weight: bold;
  font-size: $font-size-title;
  margin-bottom: $gap-small;
}

.sectionDescription {
  font-size: $font-size-xlarge;
  font-weight: $font-weight-regular;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    font-size: $font-size-large;
  }
}

.sectionInfoContainer {
  position: relative;
  margin-top: 40px;
  border: 1px solid #e0e0e0;
}

.sectionInfo {
  background-color: #f2c94c;
  border-radius: 4px 4px 0 0;
  padding: 8px 16px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.questionContainer {
  margin-top: $gap-medium;
}

.questionTitle {
  font-weight: bold;
  font-size: $font-size-large;
  margin-bottom: $gap-small;
}

.questionDescription {
  font-weight: $font-weight-regular;
  font-size: $font-size-regular;
  margin-bottom: $gap-small;

  input {
    width: 100%;
  }
}

.buttonArea {
  display: flex;
  align-items: center;
  margin-top: $gap-regular;

  > button {
    margin-right: 1rem;
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    flex-direction: column-reverse;

    > button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.imageName {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-right: 11px;
}

.questionBox {
  border: 1px solid $divider-color;
  box-sizing: border-box;
  border-radius: $border-radius-large;
  margin-left: 0;
  overflow: hidden;

  &:hover,
  &:focus-within {
    border: 1px solid $primary-color;
  }
}

.rangeContainer {
  position: relative;
}

.rangeSlider {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #828282;
  margin-top: 11px;

  input {
    width: 100%;
  }
}

.sliderTicks {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  position: absolute;
  width: 100%;
  top: 20px;

  p {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    border-left: 1px solid #828282;
    height: 14px;
    z-index: 1;
  }
}

.sliderLabels {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;

  p {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 15px;
    margin: -20px 0 10px 0;
    color: #828282;
  }
}

.mandatory {
  color: $danger-color;
}

.button {
  @media only screen and (max-width: $min-tablet-landscape-width) {
    width: 100%;
  }
}

.blobPreviewContainer {
  position: relative;
  width: max-content;
  display: flex;
  margin-top: 1rem;
}

.videoThumbnail {
  width: 10rem;
}

.videoThumbnailCloseIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  padding: 0.25rem;
  background-color: white;
  border-radius: $border-radius-round;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
}

.videoName {
  font-size: $font-size-regular;
  color: $hard-text-color;
  max-width: 9.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 0.5rem;
}

.documentName {
  text-decoration: underline;
}

.documentCloseIcon {
  cursor: pointer;
  margin-left: 0.25rem;
}

.previewArea {
  background-color: $background-color;
  height: fit-content;
  padding: $gap-regular;

  .caption {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: $gap-small;
  }

  @media only screen and (max-width: $min-tablet-landscape-width) {
    margin-bottom: $gap-regular;
  }
}

.textAreaInput {
  @include input-wrapper;

  &:focus-visible {
    outline: none;
  }
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrapper {
  display: grid;
  grid-gap: 2rem;
}

.sectionHeader {
  display: grid;
  font-weight: bold;
  font-size: 1.5rem;
  font-weight: bold;
  color: $black;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    &::after {
      width: 100%;
    }
  }
}

.subSectionHeader {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.75rem;
}

.pitchAttachments {
  display: grid;
  grid-template-columns: 28rem auto;
  grid-gap: 1rem;
  margin: 2rem 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    grid-auto-flow: row;
  }
}

.attachmentTitle {
  font-size: $font-size-regular;
  text-transform: uppercase;
  color: #808191;
  font-weight: 300;
}

.videoWrapper {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.pitchDeckText {
  color: #6b7280;
  cursor: pointer;
  text-decoration: underline;
}

.chartIcon {
  fill: #6b7280;
  margin-right: 0.25rem;
}

.markdownContent {
  ul,
  ol {
    padding-inline-start: 1.75rem;
  }
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.submissionTags {
  @include admin-item-wrapper;
  position: relative;

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  &__icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  button {
    float: right;
    margin-top: 1rem;
  }

  &__tags {
    display: flex;
    gap: 0.25rem;
  }

  &__editingTags {
    margin-bottom: 1rem;
  }
}

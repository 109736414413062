.fullscreenOpacityLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #ccc;
  z-index: 10000;
}

@import '../../styles/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.title {
  font-size: $font-size-title;
  font-weight: bold;
}

.permissions {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #f7f6f3;
}

.permission {
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  margin: 0.25rem;
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #e7d4d4;
}

.icon {
  margin: 0.65rem;
}

.owned {
  background-color:  #fff
}

.permissionMain {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.permissionCheck {
  font-size: 0.9rem;
  margin-right: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.permissionName {
  font-size: 0.9rem;
  font-weight: bold;
}

.permissionDesc {
  width: 55%;
  font-size: 0.9rem;
  color: #667;
  margin-right: 1rem;
}

li {
  margin: 1rem 0;
}

@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 1.5rem;
  }
}

.main {
  grid-column: 1 / 9;
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 1.5rem;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    grid-column: 1 / -1;
  }
}

.comments {
  grid-column: 9 / 13;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    grid-column: 1 / -1;
  }
}

@import '../../styles/variables.scss';

.ideaComment {
  position: relative;
  border-radius: 1rem;
  box-shadow: $box-shadow-base;
  font-size: $font-size-regular;
  padding: $gap-regular;

  &__editing {
    background-color: #fbf4dd;
  }

  &__options {
    position: absolute;
    top: 0.25rem;
    right: 0.75rem;
    z-index: $z-index-popup-menu;
  }

  &__optionsIcon {
    cursor: pointer;
    fill: $gray-1;
  }

  &__optionsModal {
    display: grid;
    position: absolute;
    right: 0;
    width: max-content;
    background-color: $white;
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-base;
    z-index: $z-index-popup-menu;
    overflow: hidden;
  }

  &__option {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $black;
      &[data-danger] {
        background-color: $danger-color;
      }
    }
  }
}

.editingButtons {
  display: flex;
  gap: 0.5rem;
  float: right;
  margin-top: 1rem;
}

.authorContainer {
  display: flex;
  margin-bottom: $gap-small;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: $border-radius-round;
}

.author {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1rem;
}

.authorName {
  color: $hard-text-color;
}

.commentedAt {
  font-size: $font-size-small;
  margin-top: 1rem;
}

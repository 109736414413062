@import '../../styles/variables.scss';

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-header;
  color: $hard-text-color;
  margin-bottom: $gap-regular;
}

.longParagraph {
  border: 1px solid $divider-color;
  border-radius: 8px;
  padding: $gap-small;
  font-size: $font-size-regular;
  line-height: $gap-regular;
  margin-bottom: $gap-small;
  max-height: $box-height-medium;
  overflow: scroll;
  white-space: pre-line;
}

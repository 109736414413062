@import '../../styles/variables.scss';

.actions {
  display: flex;
  justify-content: space-between;
}

.control {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.button {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.downloadEvaluationsButton {
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
}

.tabList {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 1rem;
  margin: 0;

  list-style: none;
  padding: 0;

  @media only screen and (max-width: $min-tablet-landscape-width) {
    justify-content: center;
  }
}

.tab {
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #9ca3af;
  cursor: pointer;

  &[aria-selected='true'] {
    color: #000000;
    &::after {
      content: '';
      display: block;
      border-bottom: 3px solid #000;
      border-radius: 1rem;
    }
  }
}

.descriptionContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
}

.formsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.banner {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.description {
  grid-column: 1 / 4;
}

.challengeUserGroups {
  grid-column: 4 / 5;
}

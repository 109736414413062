@import '../../styles/variables.scss';

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.5rem;
  align-items: center;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.grayOut {
  color: #9ca3af;
  fill: #9ca3af;
}
